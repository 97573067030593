import React, { useContext } from 'react';
import parse from 'html-react-parser';

import { CmsContext, TranslatesContext } from 'helpers/providers/contexts';

const useText = (id, isCms, handleString = null) => {
  const content = useContext(CmsContext);
  const translates = useContext(TranslatesContext);

  let targetString = isCms
    ? (content && content[id] && content[id].children) ||
      translates[id] ||
      `<p>${id}</p>`
    : translates[id] || `${id}`;

  if (handleString) {
    targetString = handleString(targetString);
  }

  return isCms ? (
    <div data-editable data-name={id}>
      {parse(targetString)}
    </div>
  ) : (
    parse(targetString)
  );
};

export default useText;
